<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { t } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { getFormattedNumber } from '../utils/formatters';
  import Button from '../components/Button.svelte';
  import Input from '../components/Input.svelte';
  import { topBar } from '../../store/TopBarStore';
  import { Context } from '../utils/analytics/keys';
  import { sendRequest } from '../utils/fetch';
  import { MPAY_API_BASE_URI } from '../../env';
  import { MPayApi } from '../constants';
  import { getAccountId, getCountry } from '../utils/localStorage';
  import RepaymentDialog from '../components/RepaymentDialog.svelte';
  import Icon from '../components/Icon.svelte';
  import { mCreditStore } from '../../store/mCreditStore';
  import { getCurrencyUnit } from '../utils/helpers';
  import featureFlagsStatus from '../utils/featureFlags';
  import RepaymentPlaceholder from '../components/RepaymentPlaceholder.svelte';

  let showRepaymentDialog = false;
  let repaymentAmount = (
    ($mCreditStore.mCreditSummary.dueAmount ?? 0) +
    ($mCreditStore.mCreditSummary.overdueAmount ?? 0)
  ).toFixed(2);

  const currencyCode = getCurrencyUnit($mCreditStore.creditLimit.currencyCode);
  const initialValues = {
    dueAmount: $mCreditStore.mCreditSummary.dueAmount,
    overdueAmount: $mCreditStore.mCreditSummary.overdueAmount,
    usedLimit: $mCreditStore.creditLimit.exhaustedCreditLimit,
    availableLimit: $mCreditStore.creditLimit.availableCreditLimit,
  };

  let dueAmount = initialValues.dueAmount;
  let overdueAmount = initialValues.overdueAmount;
  let usedLimit = initialValues.usedLimit;
  let availableLimit = initialValues.availableLimit;
  let maxRepaymentAmount =
    initialValues.dueAmount + initialValues.overdueAmount;

  $: inputError =
    Number(repaymentAmount) > maxRepaymentAmount
      ? `${$t('repayment.error_max_amount')} ${getFormattedNumber(maxRepaymentAmount)} ${currencyCode}`
      : null;

  $: isButtonDisabled =
    inputError !== null ||
    Number(repaymentAmount) <= 0 ||
    isNaN(Number(repaymentAmount));

  function handleRepaymentChange(input: string) {
    const value = Number(input);
    repaymentAmount = input;

    if (value <= 0 || isNaN(value)) {
      resetAmounts();
    }

    updateAmounts(value);
  }

  function resetAmounts() {
    dueAmount = initialValues.dueAmount;
    overdueAmount = initialValues.overdueAmount;
    usedLimit = initialValues.usedLimit;
    availableLimit = initialValues.availableLimit;
  }

  function updateAmounts(value: number) {
    const remainingAmount = Math.max(0, value - initialValues.overdueAmount);
    overdueAmount = Math.max(0, initialValues.overdueAmount - value);
    dueAmount = Math.max(0, initialValues.dueAmount - remainingAmount);
  }

  async function handleContinue() {
    if (isButtonDisabled) return;
    showRepaymentDialog = true;

    const response = await sendRequest<{ web_url: string }>(
      `${MPAY_API_BASE_URI}/${MPayApi.PAYMENTS}/links/create`,
      {
        method: 'POST',
        body: JSON.stringify({
          accountId: getAccountId(),
          amount: repaymentAmount,
          country: getCountry(),
        }),
      }
    );

    setTimeout(() => {
      window.open(response.web_url, '_blank');
    });

    showRepaymentDialog = false;
  }

  onMount(() => {
    if (!featureFlagsStatus.repayment) {
      return window.history.back();
    }

    topBar.setTitle($t('repayment.title'));
    topBar.setContext(Context.Repayment);

    updateAmounts(Number(repaymentAmount));
  });

  onDestroy(() => {
    topBar.reset();
  });
</script>

{#if featureFlagsStatus.repayment}
  <Layout>
    <div class="bg-primary-darken20 px-5 pb-6 pt-8">
      <h1 class="mb-6 text-ios-title1 text-white">{$t('repayment.header')}</h1>

      <div class="mb-6">
        <Input
          id="repayment-amount"
          type="number"
          label={$t('repayment.total')}
          value={repaymentAmount}
          {currencyCode}
          onChange={handleRepaymentChange}
          error={inputError}
        />
      </div>

      <Button
        label={$t('general.cta.continue')}
        onClick={handleContinue}
        type="default"
        isDisabled={isButtonDisabled}
      />
    </div>

    <div class="mt-8 px-5">
      <h2 class="mb-3 text-ios-title2 font-semibold text-primary-base">
        {$t('repayment.balance_after_header')}
      </h2>

      <ul class="text-ios-body text-primary-base">
        <li
          class="border-gray-200 flex h-[52px] items-center justify-between border-b"
        >
          <span>{$t('mcredit.due_amount')}</span>
          <span
            class="text-ios-headline font-semibold"
            data-cy="due-amount-holder"
          >
            {getFormattedNumber(dueAmount)}
            {currencyCode}
          </span>
        </li>
        <li
          class="border-gray-200 flex h-[52px] items-center justify-between border-b"
        >
          <span>{$t('mcredit.overdue_amount')}</span>
          <span
            class={`flex items-center text-ios-headline font-semibold ${overdueAmount > 0 ? 'text-red-base' : ''}`}
            data-cy="overdue-amount-holder"
          >
            {#if overdueAmount > 0}
              <span class="mr-2" data-cy="overdue-amount-icon">
                <Icon iconName="Warning" size="16" />
              </span>
            {/if}

            {getFormattedNumber(overdueAmount)}
            {currencyCode}
          </span>
        </li>
        <li
          class="border-gray-200 flex h-[52px] items-center justify-between border-b"
        >
          <span>{$t('mcredit.used_limit')}</span>
          <span class="text-ios-headline font-semibold">
            {getFormattedNumber(usedLimit)}
            {currencyCode}
          </span>
        </li>
        <li class="flex h-[52px] items-center justify-between">
          <span>{$t('mcredit.available_limit')}</span>
          <span class="text-ios-headline font-semibold">
            {getFormattedNumber(availableLimit)}
            {currencyCode}
          </span>
        </li>
      </ul>
    </div>

    <RepaymentDialog bind:showRepaymentDialog />
  </Layout>
{:else}
  <RepaymentPlaceholder />
{/if}
