<script lang="ts">
  import { t } from 'svelte-i18n';
  import { sendRequest } from '../utils/fetch';
  import NoInvoices from './NoInvoices.svelte';
  import { MPAY_API_BASE_URI } from '../../env';
  import {
    ERR_429,
    MCREDIT_CODE,
    CreditSettleFrequency,
    MPayApi,
    DateRange,
  } from '../constants';
  import { onMount } from 'svelte';
  import {
    EventsMapKeys,
    passBackendTracking,
    trackEvents,
  } from '../utils/tracking';
  import type { Invoice } from '../../types/mpay.types';
  import InvoicesPlaceholder from './InvoicesPlaceholder.svelte';
  import { groupInvoices } from '../utils/creditFrequency';
  import { getMergedInvoices } from '../utils/invoices';
  import LinkButton from './LinkButton.svelte';
  import { mCreditStore } from '../../store/mCreditStore';
  import { errorDialogStore } from '../../store/errorDialogStore';
  import { getAccountId, getCountry } from '../utils/localStorage';
  import { formatDate, calculateDateRange } from '../utils/date';
  import type { Pagination } from '../../types/globals.types';
  import InvoicesList from './InvoicesList.svelte';
  import { topBar } from '../../store/TopBarStore';
  import { Context } from '../utils/analytics/keys';

  let isDataLoading = false;
  let invoicesError: unknown | null = null;
  let groupBy: CreditSettleFrequency;

  onMount(() => {
    // TODO: a more sophisticated and thorough check if to load new invoices or not
    if (Object.keys($mCreditStore.invoices).length > 0) {
      return;
    }
    fetchInvoices();
  });

  const fetchMoreInvoices = () => {
    if ($mCreditStore.pagination.totalPages <= $mCreditStore.currentPage) {
      return;
    }
    trackEvents(EventsMapKeys.LOAD_MORE_INVOICES, {
      category: $topBar.context,
      requestedPage: $mCreditStore.currentPage + 1,
    });
    mCreditStore.setCurrentPage($mCreditStore.currentPage + 1);
    fetchInvoices(true);

    setTimeout(() => {
      window.scrollBy({
        top: window.innerHeight - 150,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  function constructUrlWithParams() {
    const baseUrl = `${MPAY_API_BASE_URI}/${MPayApi.INVOICES}/${getCountry()}/${getAccountId()}`;
    const { startDate, endDate } = calculateDateRange(
      DateRange.THIS_AND_LAST_YEAR
    );

    const queryParams = new URLSearchParams({
      limit: '100',
      from: formatDate(startDate),
      to: formatDate(endDate),
      page: `${$mCreditStore.currentPage}`,
      'paymentCode[]': MCREDIT_CODE,
    }).toString();

    return `${baseUrl}?${queryParams}`;
  }

  const mergedInvoiceData = (invoices: Invoice[]) => {
    const newData = groupInvoices(invoices, groupBy);
    const storedData = $mCreditStore.invoices;

    return getMergedInvoices(storedData, newData);
  };

  async function fetchInvoices(fetchNextPage = false) {
    isDataLoading = true;
    invoicesError = null;

    try {
      const response = await sendRequest<{
        invoices: Invoice[];
        _pagination: Pagination;
      }>(constructUrlWithParams());

      if (!fetchNextPage) {
        groupBy = $mCreditStore.creditSettle.creditTpContract;
      }
      passBackendTracking('responseInvoiceCount', {
        value: response.invoices.length,
        tab: Context.Mcredit,
      });

      mCreditStore.setInvoices({
        invoices: fetchNextPage
          ? mergedInvoiceData(response.invoices)
          : groupInvoices(response.invoices, groupBy),
        pagination: response._pagination,
      });
    } catch (err) {
      invoicesError = err;
      if (fetchNextPage) {
        mCreditStore.setCurrentPage($mCreditStore.currentPage - 1);
      }

      if (
        invoicesError!.toString() !== ERR_429 ||
        !invoicesError!.toString().includes(ERR_429)
      ) {
        errorDialogStore.setRetryAction(fetchInvoices);
      }
    } finally {
      isDataLoading = false;
    }
  }
</script>

<section class="pt-6" data-cy="mcredit-invoices">
  {#if !isDataLoading && !invoicesError && Object.keys($mCreditStore.invoices).length === 0}
    <NoInvoices label={$t('invoices.no_invoices')} />
  {:else}
    {#each Object.keys($mCreditStore.invoices) as invoicesPeriod, index}
      <InvoicesList
        id="mcredit-invoices-list"
        timePeriod={invoicesPeriod}
        {index}
        invoices={$mCreditStore.invoices}
        showBadge
      />
    {/each}

    {#if $mCreditStore.pagination.totalPages > $mCreditStore.currentPage}
      <div class="flex justify-center">
        <LinkButton
          id="load-more-mcredit-invoices"
          isDisabled={isDataLoading}
          onClick={fetchMoreInvoices}
          label={$t('general.cta.load_more')}
        />
      </div>
    {/if}
  {/if}

  {#if isDataLoading}
    <InvoicesPlaceholder />
  {/if}
</section>
