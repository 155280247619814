<script lang="ts">
  import { t } from 'svelte-i18n';
  import image from '../../assets/images/finqware.png';
  import Icon from './Icon.svelte';

  export let showRepaymentDialog = false;

  let dialog: HTMLDialogElement;

  $: if (dialog && showRepaymentDialog) dialog.showModal();
  $: if (dialog && !showRepaymentDialog) dialog.close();
</script>

<dialog
  data-cy="repayment-dialog"
  class="absolute w-auto rounded-xl"
  bind:this={dialog}
>
  <div class="flex flex-col pt-8">
    <div class="mb-6 flex items-center justify-center">
      <Icon iconName="Spinner" size="33" />
    </div>

    <div
      class="px-6 pb-8 text-center text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
    >
      {$t('repaymentDialog.title')}
    </div>

    <div class="border-t border-t-primary-tint90 px-6 pb-6 pt-4">
      <img src={image} alt="finqware" />
      <div class="pt-2 font-roboto text-roboto-caption">
        {$t('repaymentDialog.description')}
      </div>
    </div>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
</style>
